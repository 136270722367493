import React from 'react';
import './About.css';

function About() {

  return (
    <div className="about-container">
      <p>Plotline is a project that started in the Spring of 2022 to revisit the user experience of data science tools to focus on the needs of business leaders.</p>
    </div>
  );
}

export default About;