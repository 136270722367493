import React from 'react';
import './Stories.css';
import { ButtonExplore } from './Button';
import { Link } from 'react-router-dom';

function Stories() {

  return (
  		<div>
			<img width="200" src="/img/PL.svg" alt="Plotline" />
			<div className='stories-float-left-child'>
				<div className="float-inner-container">
					<h3>
						“The most expensive item is the Grilled Chicken”
					</h3>
					<img width="300" src="/img/plot1.jpg" alt="Plot 1" />
					<br/>
					<ButtonExplore>Explore</ButtonExplore>
				</div>
				<div className="float-inner-container">
					<h3>“Grilled Chicken is the least profitable item</h3>
					<img width="260" src="/img/plot2.jpg" alt="Plot 2" />
					<br/>
					<ButtonExplore>Explore</ButtonExplore>
				</div>
				<div className="float-inner-container">
					<h3>“The BLT is the worst selling item”</h3>
					<img width="343" src="/img/plot3.jpg" alt="Plot 3" />
					<br/>
					<ButtonExplore>Explore</ButtonExplore>
				</div>
			</div>
  		</div>
  	)
}


export default Stories;