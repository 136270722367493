import React from 'react';
import './Body.css';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import { Button } from './Button';

function Body() {
  return (
  	<div className='body-container'>
  		<div className='float-container'>
  		<center><img width="800" src="/img/PL.svg" alt="Plotline" /></center>
  		<center><h1>Analytics simplified</h1></center>
  		<br/>
  		<br/>
  		<center><h3>Join the revolution</h3></center>
        <br/>
        <div className='float-left-child-main'>
          <form>
            <input
              className='text-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <br/>
            <Button buttonStyle='btn'>Subscribe</Button>
          </form>
        </div>

  		</div>
  	</div>
  );
}

export default Body;

