import React from 'react';
import './Explore.css';
import { ButtonExplore } from './Button';
import { Link } from 'react-router-dom';

function Explore() {

  return (
  		<div>
			<img width="200" src="/img/PL.svg" alt="Plotline" />
			<div className='explore-float-left-child'>
				<div className="float-inner-container">
					<h3>
						“The most expensive item is the Grilled Chicken”
					</h3>
					<img width="300" src="/img/plot1.jpg" alt="Plot 1" />
					<br/>
					<ButtonExplore>Explore</ButtonExplore>
				</div>
				<div className="float-inner-container">
					<h3>“Grilled Chicken is the least profitable item”</h3>
					<img width="260" src="/img/plot2.jpg" alt="Plot 2" />
					<br/>
					<ButtonExplore>Explore</ButtonExplore>
				</div>
				<div className="float-inner-container">
					<h3>“The BLT is the worst selling item”</h3>
					<img width="343" src="/img/plot3.jpg" alt="Plot 3" />
					<br/>
					<ButtonExplore>Explore</ButtonExplore>
				</div>
			</div>
			<br/>
			<br/>
			<div className='float-bottom-child'>
				<div className="float-bottom-inner-container ">
					<h3>
						“My Store in Boston has the largest sales”
					</h3>
					<img width="300" src="/img/plot1.jpg" alt="Plot 1" />
				</div>
				<div className="float-bottom-inner-container ">
					<h3>“Grilled Chicken is the worst selling item in My Store Boston”</h3>
					
				</div>
				<div className="float-bottom-inner-container ">
					<h3>“Chicken is the most expensive item for Grilled Chicken”</h3>
					
				</div>
			</div>
  		</div>
  	)
}


export default Explore;