import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      
        
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='plotline-logo'>
            Plotline
          </Link>
          </div>
          <div class='footer-logo'>
            <small class='website-rights'>Plotline © 2022</small>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
