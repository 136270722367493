import React from 'react';
import './Prototype.css';
import { ButtonSearch } from './Button';
import { Link } from 'react-router-dom';

function Prototype() {

  return (
    <div className='prototype-container'>
  		<center><img width="400" src="/img/PL.svg" alt="Plotline" /></center>
  		<div className='float-left-child'>
          <form>
            <input
              className='text-input'
              name='email'
              type='email'
              placeholder='How do you want to grow your business?'
            />
            <ButtonSearch buttonStyle='btn'>Go</ButtonSearch>
          </form>
          
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className='float-center-child'>
	        
	        <div className='float-center'>
	        	<div className='float-alert-left'>
	        		<center><img width="100" src="img/insight.svg" alt="Plotline" /></center>
	        	</div>
	        	<div className='float-alert-right'>
	        		<h3>“Your PromoCupons increased sales by 20%”</h3>
	        	</div>
	        </div>
	     
       		<br/>
       		<div className='float-center'>
	        	<div className='float-alert-left'>
	        		<center><img width="100" src="img/trend.svg" alt="Plotline" /></center>
	        	</div>
	        	<div className='float-alert-right'>
	        		<h3>“Sales have increased by 15% in the last 3 months”</h3>
	        	</div>
	        </div>
	       <br/>
       		<div className='float-center'>
	        	<div className='float-alert-left'>
	        		<center><img width="100" src="img/risks.svg" alt="Plotline" /></center>
	        	</div>
	        	<div className='float-alert-right'>
	        		<h3>“Your sales are below the industry average”</h3>
	        	</div>
	        </div>
        </div>
    </div>
  );
}

export default Prototype;