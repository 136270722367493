import React from 'react';
import ReactDOM from 'react-dom/client';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Body from './components/Body';
import About from './components/About';
import Prototype from './components/Prototype';
import Stories from './components/Stories';
import Explore from './components/Explore';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './global.css';

function App () {
  return (
  	<div>
  	<Router>
  			<Navbar/>
  			<Routes>
  				<Route exact path='/' element={<Body/>}></Route>
				<Route path='/about' element={<About/>}></Route>
				<Route path='/prototype' element={<Prototype/>}></Route>
				<Route path='/stories' element={<Stories/>}></Route>
				<Route path='/explore' element={<Explore/>}></Route>
  			</Routes>
  			<Footer/>
  	</Router>
  	</div>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);